import { createArray } from 'src/utils';

export const MERCHANTS = [
  {
    id: 0,
    imgLogo: require('src/images/campaign/campaign_20240424_N_paycashback/merchants/0.png'),
    destinationURL: 'https://www.buyma.com/?af=987',
  },
  {
    id: 1,
    imgLogo: require('src/images/campaign/campaign_20240424_N_paycashback/merchants/1.png'),
    destinationURL: 'https://fril.jp/',
  },
  {
    id: 2,
    imgLogo: require('src/images/campaign/campaign_20240424_N_paycashback/merchants/2.png'),
    destinationURL: 'https://yz-store.com/',
  },
  {
    id: 3,
    imgLogo: require('src/images/campaign/campaign_20240424_N_paycashback/merchants/3.png'),
    destinationURL: 'https://www.urban-research.jp/',
  },
  {
    id: 4,
    imgLogo: require('src/images/campaign/campaign_20240424_N_paycashback/merchants/4.png'),
    destinationURL: 'https://store.united-arrows.co.jp/',
  },
  {
    id: 5,
    imgLogo: require('src/images/campaign/campaign_20240424_N_paycashback/merchants/5.png'),
    destinationURL: 'https://www.ralphlauren.co.jp/',
  },
  {
    id: 6,
    imgLogo: require('src/images/campaign/campaign_20240424_N_paycashback/merchants/6.png'),
    destinationURL: 'https://www.samantha.co.jp/shop/default.aspx',
  },
  {
    id: 7,
    imgLogo: require('src/images/campaign/campaign_20240424_N_paycashback/merchants/7.png'),
    destinationURL: 'https://www.y-aoyama.jp/',
  },
  {
    id: 8,
    imgLogo: require('src/images/campaign/campaign_20240424_N_paycashback/merchants/8.png'),
    destinationURL: 'https://www.hoshinoresorts.com/',
  },
  {
    id: 9,
    imgLogo: require('src/images/campaign/campaign_20240424_N_paycashback/merchants/9.png'),
    destinationURL: 'https://www.sakuratravel.jp/',
  },
  {
    id: 10,
    imgLogo: require('src/images/campaign/campaign_20240424_N_paycashback/merchants/10.png'),
    destinationURL:
      'https://www.airtrip.jp/?utm_source=airtrip.jp&utm_medium=web&utm_campaign=paidy_cashbackcampaign_from20240424',
  },
  {
    id: 11,
    imgLogo: require('src/images/campaign/campaign_20240424_N_paycashback/merchants/11.png'),
    destinationURL: 'https://www.dmm.com/',
  },
  {
    id: 12,
    imgLogo: require('src/images/campaign/campaign_20240424_N_paycashback/merchants/12.png'),
    destinationURL: 'https://shop.hololivepro.com/',
  },
  {
    id: 13,
    imgLogo: require('src/images/campaign/campaign_20240424_N_paycashback/merchants/13.png'),
    destinationURL:
      'https://whowatch.tv/?utm_source=paidy&utm_medium=display&utm_campaign=paidy2404',
  },
  {
    id: 14,
    imgLogo: require('src/images/campaign/campaign_20240424_N_paycashback/merchants/14.png'),
    destinationURL: 'https://dwango-ticket.jp/',
  },
  {
    id: 15,
    imgLogo: require('src/images/campaign/campaign_20240424_N_paycashback/merchants/15.png'),
    destinationURL: 'https://www.soundhouse.co.jp/',
  },
  {
    id: 16,
    imgLogo: require('src/images/campaign/campaign_20240424_N_paycashback/merchants/16.png'),
    destinationURL: 'https://www.soundhouse.co.jp/',
  },
  {
    id: 17,
    imgLogo: require('src/images/campaign/campaign_20240424_N_paycashback/merchants/17.png'),
    destinationURL: 'https://ec.geo-online.co.jp/shop/',
  },
  {
    id: 18,
    imgLogo: require('src/images/campaign/campaign_20240424_N_paycashback/merchants/18.png'),
    destinationURL: 'https://www.yamada-denkiweb.com/',
  },
  {
    id: 19,
    imgLogo: require('src/images/campaign/campaign_20240424_N_paycashback/merchants/19.png'),
    destinationURL: 'https://www.kojima.net/ec/index.html',
  },
  {
    id: 20,
    imgLogo: require('src/images/campaign/campaign_20240424_N_paycashback/merchants/20.png'),
    destinationURL: 'https://shop.tsukumo.co.jp/',
  },
  {
    id: 21,
    imgLogo: require('src/images/campaign/campaign_20240424_N_paycashback/merchants/21.png'),
    destinationURL: 'https://www.frontier-direct.jp/',
  },
  {
    id: 22,
    imgLogo: require('src/images/campaign/campaign_20240424_N_paycashback/merchants/22.png'),
    destinationURL: 'https://www.irisplaza.co.jp/',
  },
  {
    id: 23,
    imgLogo: require('src/images/campaign/campaign_20240424_N_paycashback/merchants/23.png'),
    destinationURL: 'https://fril.jp/',
  },
  {
    id: 24,
    imgLogo: require('src/images/campaign/campaign_20240424_N_paycashback/merchants/24.png'),
    destinationURL:
      'https://greenfunding.jp/?utm_source=Paidy&utm_medium=GREENFUNDING&utm_campaign=Paidy-2404',
  },
  {
    id: 25,
    imgLogo: require('src/images/campaign/campaign_20240424_N_paycashback/merchants/25.png'),
    destinationURL: 'https://francfranc.com/',
  },
  {
    id: 26,
    imgLogo: require('src/images/campaign/campaign_20240424_N_paycashback/merchants/26.png'),
    destinationURL: 'https://rasik.style/',
  },
  {
    id: 27,
    imgLogo: require('src/images/campaign/campaign_20240424_N_paycashback/merchants/27.png'),
    destinationURL: 'https://www.irisplaza.co.jp/',
  },
  {
    id: 28,
    imgLogo: require('src/images/campaign/campaign_20240424_N_paycashback/merchants/28.png'),
    destinationURL: 'https://www.tansu-gen.jp/',
  },
  {
    id: 29,
    imgLogo: require('src/images/campaign/campaign_20240424_N_paycashback/merchants/29.png'),
    destinationURL: 'https://ec.geo-online.co.jp/shop/',
  },
  {
    id: 30,
    imgLogo: require('src/images/campaign/campaign_20240424_N_paycashback/merchants/30.png'),
    destinationURL: 'https://www.yamada-denkiweb.com/',
  },
  {
    id: 31,
    imgLogo: require('src/images/campaign/campaign_20240424_N_paycashback/merchants/31.png'),
    destinationURL: 'https://store.united-arrows.co.jp/',
  },
  {
    id: 32,
    imgLogo: require('src/images/campaign/campaign_20240424_N_paycashback/merchants/32.png'),
    destinationURL: 'https://shop.hololivepro.com/',
  },
  {
    id: 33,
    imgLogo: require('src/images/campaign/campaign_20240424_N_paycashback/merchants/33.png'),
    destinationURL:
      'https://greenfunding.jp/?utm_source=Paidy&utm_medium=GREENFUNDING&utm_campaign=Paidy-2404',
  },
];

export const MERCHANT_GROUPED_BY_CATEGORY = [
  {
    categoryValue: 1,
    merchantIds: createArray(0, 7),
  },
  {
    categoryValue: 2,
    merchantIds: createArray(8, 10),
  },
  {
    categoryValue: 3,
    merchantIds: createArray(11, 15),
  },
  {
    categoryValue: 4,
    merchantIds: createArray(16, 24),
  },
  {
    categoryValue: 5,
    merchantIds: createArray(25, 31),
  },
  {
    categoryValue: 6,
    merchantIds: createArray(32, 33),
  },
];

export const TAGS = [
  { value: 0, label: 'すべて見る' },
  { value: 1, label: 'ファッション' },
  { value: 2, label: '航空券・ホテル予約' },
  { value: 3, label: '動画・ライブ配信・ホビーグッズ' },
  { value: 4, label: '家電・デジタル機器' },
  { value: 5, label: '家具・インテリア雑貨' },
  { value: 6, label: 'その他' },
];

export const FIXED_MERCHANTS = [];

export const MERCHANTS_GROUPED_BY_FIXED_TAGS = [];

export const FIXES_TAGS = [];
