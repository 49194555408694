import React from 'react';
import styles from './SectionMerchantGrid.module.scss';
import MerchantsGridWithAccordion from './MerchantsGridWithAccordion';

export default function SectionMerchantGrid() {
  return (
    <section className={styles.section}>
      <p className={styles.title}>キャンペーン対象ショップ</p>
      <MerchantsGridWithAccordion notShowButtonBottom />
    </section>
  );
}
