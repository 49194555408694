import React, { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import {
  Chip,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import ArrowDown from 'src/images/arrow-down-icon.svg';
import styles from './MerchantsGridWithAccordion.module.scss';
import {
  MERCHANTS,
  TAGS,
  MERCHANT_GROUPED_BY_CATEGORY,
  FIXES_TAGS,
  FIXED_MERCHANTS,
  MERCHANTS_GROUPED_BY_FIXED_TAGS,
} from './MerchantsGridWithAccordionData';

const ExpandMoreIcon = () => (
  <img src={ArrowDown} width={20} height={12} alt="expand-icon" />
);

function scrollToElement(id) {
  if (!document) {
    return;
  }

  const el = document.getElementById(id);

  el.scrollIntoView();
}

const isMobileBreadkpoint =
  typeof window !== 'undefined' ? window.innerWidth <= 600 : false;

const DEFAULT_TAG = TAGS[0].value;
const GROUPED_TAGS = TAGS.slice(1);

const useMerchantList = () => {
  const [activeCategory, setActiveCategory] = useState([]);

  function chooseCategory(category) {
    // If select default tag, then remove other tags, only add default tag
    if (
      category === DEFAULT_TAG &&
      activeCategory.length > 0 &&
      !activeCategory.includes(DEFAULT_TAG)
    ) {
      setActiveCategory([DEFAULT_TAG]);

      return;
    }

    // Deselect the tag
    if (activeCategory.includes(category)) {
      const newCategories = activeCategory.filter((tag) => tag !== category);

      setActiveCategory(newCategories);

      return;
    }

    // Select a new tag
    const newCategories = [
      ...activeCategory.filter((tag) => tag !== DEFAULT_TAG),
      category,
    ];

    setActiveCategory(newCategories);

    if (isMobileBreadkpoint) {
      scrollToElement(`tag-${category}`);
    }
  }

  function resetCategory() {
    setActiveCategory([]);
  }

  return {
    categories: TAGS,
    activeCategory,
    chooseCategory,
    resetCategory,
  };
};

const MerchantFilterer = ({ activeCategory, categories, chooseCategory }) => {
  return (
    <div className={styles.merchantFilterer} id="grid-filter">
      {categories.map((cate) => (
        <Chip
          key={cate.value}
          label={cate.label}
          onClick={() => chooseCategory(cate.value)}
          clickable
          color="secondary"
          className={cx(styles.filterButton, {
            [styles.activeFilterButton]: activeCategory.includes(cate.value),
          })}
        />
      ))}
    </div>
  );
};

const MerchantCard = ({ imgLogo, id, destinationURL }) => {
  return (
    <div className={styles.merchantCard}>
      <a href={destinationURL} target="_blank" rel="noopener noreferrer">
        <img src={imgLogo} alt={id} width={219} height={97} />
      </a>
    </div>
  );
};

const renderMerchantsGroupedByTag = (tag) => {
  const { merchantIds } = MERCHANT_GROUPED_BY_CATEGORY.find(
    ({ categoryValue }) => categoryValue === tag.value
  );
  const merchants = merchantIds.map((id) =>
    MERCHANTS.find((merchant) => merchant.id === id)
  );

  return (
    <>
      {merchants.map((merchant) => (
        <MerchantCard key={merchant.id} {...merchant} />
      ))}
    </>
  );
};

const renderMerchantsGroupedByFixedTag = (tag) => {
  const { merchantIds } = MERCHANTS_GROUPED_BY_FIXED_TAGS.find(
    ({ categoryValue }) => categoryValue === tag.value
  );
  const merchants = merchantIds.map((id) =>
    FIXED_MERCHANTS.find((merchant) => merchant.id === id)
  );

  return (
    <>
      {merchants.map((merchant) => (
        <MerchantCard key={merchant.id} {...merchant} />
      ))}
    </>
  );
};

const MerchantsGridWithAccordion = ({ notShowButtonBottom }) => {
  const {
    categories,
    activeCategory,
    chooseCategory,
    resetCategory,
  } = useMerchantList();

  const [displayBtnBottom, setDisplayBtnBottom] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');

  const handleScrollOverFilter = useCallback(() => {
    if (!document || !window) {
      return;
    }

    const elFilter = document.getElementById('grid-filter');

    if (window.scrollY > elFilter.offsetTop + elFilter.offsetHeight) {
      setDisplayBtnBottom(true);
    } else {
      setDisplayBtnBottom(false);
    }
  }, []);

  useEffect(() => {
    if (isMobile) {
      window.addEventListener('scroll', handleScrollOverFilter);
    }

    return () => {
      if (isMobile) {
        window.removeEventListener('scroll', handleScrollOverFilter);
      }
    };
  }, [isMobile, handleScrollOverFilter]);

  return (
    <div className={styles.merchantGridWrapper}>
      <MerchantFilterer
        activeCategory={activeCategory}
        categories={categories}
        chooseCategory={chooseCategory}
      />
      <div className={styles.merchantGrid}>
        {/* Render tags not have expanion */}
        {FIXES_TAGS.map((tag) => (
          <Accordion
            id={`tag-${tag.value}`} // tag-1
            key={tag.value}
            expanded
          >
            <AccordionSummary expandIcon={null}>{tag.label}</AccordionSummary>
            <AccordionDetails>
              {renderMerchantsGroupedByFixedTag(tag)}
            </AccordionDetails>
          </Accordion>
        ))}
        {GROUPED_TAGS.map((tag) => (
          <Accordion
            id={`tag-${tag.value}`} // tag-1
            key={tag.value}
            expanded={
              activeCategory.includes(tag.value) ||
              activeCategory.includes(DEFAULT_TAG)
            }
            onChange={() => chooseCategory(tag.value)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              {tag.label}
            </AccordionSummary>
            <AccordionDetails>
              {renderMerchantsGroupedByTag(tag)}
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
      {!notShowButtonBottom && isMobile && displayBtnBottom && (
        <a
          className={styles.btnBottom}
          href="#title-merchants"
          onClick={resetCategory}
        >
          <img
            src={require('src/images/chevron-up.svg')}
            alt="chevron"
            width={24}
            height={24}
          />
          <span>カテゴリーから探す</span>
        </a>
      )}
    </div>
  );
};

export default MerchantsGridWithAccordion;
